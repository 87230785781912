import React from 'react';
import PropTypes from 'prop-types';
import { delay } from 'lodash';

import getDisplayName from 'utils/getDisplayName';

const NOTIFICATION_LIFETIME = 3000;

const closeableNotification = (WrappedComponent) => {
  class CloseableNotification extends React.Component {
    static propTypes = {
      hideNotification: PropTypes.func.isRequired,
      id: PropTypes.string.isRequired,
      context: PropTypes.string.isRequired,
      kind: PropTypes.string.isRequired,
    }

    static displayName = `closeableNotification(${getDisplayName(WrappedComponent)})`;

    componentDidMount = () => {
      delay(this.handleClose, NOTIFICATION_LIFETIME);
    }

    componentWillUnmount = () => {
      this.handleClose();
    }

    handleClose = () => {
      const { id, context, hideNotification } = this.props;
      hideNotification({ id, context });
    }

    render = () => (
      <WrappedComponent
        {...this.props}
        onClose={this.handleClose}
      />
    );
  }

  return CloseableNotification;
};

export default closeableNotification;
