import cookies from 'next-cookies';

import { USER_TYPE } from 'constants/users';

import redirect from 'utils/redirect';

export default (accessLevel, ctx) => {
  const { tokens, userType, isLoggedIn } = cookies(ctx);
  const { res: response } = ctx;

  if (!accessLevel && isLoggedIn && tokens) {
    return redirect({
      href: userType === USER_TYPE.user ? '/appointments' : '/patient',
      response,
    });
  }

  if (accessLevel === USER_TYPE.user && (accessLevel !== userType || !tokens)) {
    const query = (ctx.pathname === '/account_settings' && ctx.query.token)
      ? { isConfirmNewEmail: true } : {};

    return redirect({
      href: userType === USER_TYPE.userOnboarding ? '/onboarding' : '/login',
      response,
      query,
    });
  }

  if (accessLevel === USER_TYPE.patient && (accessLevel !== userType || !tokens)) {
    return redirect({
      href: '/patient/login',
      response,
    });
  }

  return true;
};
